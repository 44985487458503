import create, { State } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { Letter } from './GameLogic';



//hp, max, incoming, letters, buffs, turn
interface HealthStore {
    currentHealth: number
    maxHealth: number
    incomingDamage: number
    setCurrentHealth: (value: number) => void
    setMaxHealth: (value: number) => void
    setIncomingDamage: (value: number) => void
    decreaseCurrentHealth: (by: number) => void
}

const useHealthStore = create<HealthStore>()(
    devtools(
        persist(
            (set) => ({
                currentHealth: 11,
                maxHealth: 11,
                incomingDamage: 0,
                setCurrentHealth: (value) => set((state) => ({ currentHealth: value })),
                setMaxHealth: (value) => set((state) => ({ maxHealth: value })),
                setIncomingDamage: (value) => set((state) => ({ incomingDamage: value })),
                decreaseCurrentHealth: (by) => set((state) => ({ currentHealth: state.currentHealth - by })),
            }),
            {
                name: 'health-store',
            }
        )
    )
)

interface LetterStore {
    // letters: Array<string>
    unusedLetters: Array<Letter>
    writtenLetters: Array<Array<Letter>>
    allLetters: Array<Letter>
    // setLetters: (value: Array<string>) => void
    writeLetter: (value: Letter) => void
    eraseLetter: () => void
    setUnusedLetters: (value: Array<Letter>) => void
    setAllLetters: (value: Array<Letter>) => void
    setNewLetters: (value: Array<Letter>) => void
    addSpace: () => void
}

const useLetterStore = create<LetterStore>()(
    // devtools(
    //     persist(
    (set) => ({
        unusedLetters: [],
        writtenLetters: [[]],
        allLetters: [],
        writeLetter: (letter) => set((state) => {
            // if (letter === ' '):
            //remove letter from unused and add to used
            let unusedLetters = [...state.unusedLetters]
            let ind = unusedLetters.indexOf(letter)
            unusedLetters.splice(ind, 1)
            let writtenLetters = [...state.writtenLetters]
            writtenLetters.push([...writtenLetters.pop()!, letter])
            return { unusedLetters, writtenLetters }
        }),
        eraseLetter: () => set((state) => {
            //remove letter or empty arr from written and add to unused
            let unusedLetters = [...state.unusedLetters]
            let writtenLetters = [...state.writtenLetters]
            if (writtenLetters[0][0] !== undefined) {
                let word = writtenLetters.pop()!
                if (word[0] !== undefined) {
                    let letter = word.pop()
                    letter && unusedLetters.push(letter)
                    writtenLetters.push(word)
                }
            }

            return { unusedLetters, writtenLetters }
        }),
        setUnusedLetters: (letters) => set((state) => ({ unusedLetters: letters })),
        setAllLetters: (letters) => set((state) => ({ allLetters: letters })),
        setNewLetters: (letters) => set((state) => ({ unusedLetters: letters, writtenLetters: [[]], allLetters: letters })),
        addSpace: () => set((state) => {
            //remove letter or empty arr from written and add to used
            let writtenLetters = [...state.writtenLetters]
            writtenLetters.push([])

            return { writtenLetters }
        }),
    }),
    //         {
    //             name: 'letter-store',
    //         }
    //     )
    // )
)


interface GameInfoStore {
    turn: number
    maxTurn: number
    level: number
    setTurn: (value: number) => void
    setMaxTurn: (value: number) => void
    setLevel: (value: number) => void
}

const useGameInfoStore = create<GameInfoStore>()(
    devtools(
        persist(
            (set) => ({
                turn: 0,
                maxTurn: 5,
                level: 0,
                setTurn: (value) => set((state) => ({ turn: value })),
                setMaxTurn: (value) => set((state) => ({ maxTurn: value })),
                setLevel: (value) => set((state) => ({ level: value })),
            }),
            {
                name: 'game-info-store',
            }
        )
    )
)

const useOverlayKeyboardStore = create<{ text: string, eraseText: () => void, write: (letter: string) => void, eraseLetter: () => void }>()(
    (set) => ({
        text: '',
        eraseText: () => set((state) => ({ text: '' })),
        write: (letter: string) => set((state) => ({ text: state.text + letter })),
        eraseLetter: () => set((state) => ({ text: state.text.substring(0, state.text.length - 1) }))
    })
)

export { useHealthStore, useLetterStore, useGameInfoStore, useOverlayKeyboardStore }