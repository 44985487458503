import './Tutorial.css';

function Tutorial() {

  return (
    <div className='Tutorial' style={{}}>
      <p>Write a word, then press the red attack button.</p>
      {/* <p>If you don't write any words, pressing the attack button will skip the turn.</p> */}
    </div>
  );
}

export default Tutorial;
