import React, { useEffect } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './ItemChoice.css';

import { Item } from '../../GameLogic';
import { useLetterStore, useHealthStore } from '../../GameStore'


interface ItemChoiceProps {
  item: Item
  selected: boolean
  setSelectedItem: (name: string) => void

}

function ItemChoice(props: ItemChoiceProps) {
  const [unusedLetters, writtenLetters, setNewLetters] = useLetterStore((state) => ([state.unusedLetters, state.writtenLetters, state.setNewLetters]))
  // console.log(wordExists('doggies'))
  const [maxHealth, setMaxHealth] = useHealthStore((state) => ([state.maxHealth, state.setMaxHealth]))
  const [currentHealth, setCurrentHealth] = useHealthStore((state) => ([state.currentHealth, state.setCurrentHealth]))
  const [incomingDamage, setIncomingDamage] = useHealthStore((state) => ([state.incomingDamage, state.setIncomingDamage]))

  useEffect(() => {

  }, [])
  return (
    <div className={`ItemChoice ${props.item.rarity} ${props.selected && 'selected'}`} onClick={() => props.setSelectedItem(props.item.name)}>
      <div className='item-choice-icon-container'>
        <img src={`/icons/${props.item.icon}`} />
      </div>
      <div className='item-choice-text-container'>
        <p className='item-choice-title'>{props.item.name}</p>
        <p className='item-choice-description'>{props.item.description}</p>
      </div>
    </div>
  );
}

export default ItemChoice;
