import React, { useEffect } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './WordFlat.css';

// import { GameState, wordExists, getNewEnemy, doTurn } from '../GameLogic';
import { useLetterStore } from '../../GameStore'
import { Letter, character, wordExists, getCommonLetters } from '../../GameLogic';


// interface WordProps {
//   gameState: GameState
//   updateState: () => void
// }


function WordFlat(props: { word: Letter[], currentWord: boolean, previousWord: Letter[] }) {
  // let [writtenLetters] = useLetterStore((state) => ([state.writtenLetters,]))

  let exists: boolean = character.seeExists && props.word[0] !== undefined && wordExists(props.word)
  let commonLetters = character.wordComboMultiplier > 0 ? getCommonLetters(props.word, props.previousWord) : []

  useEffect(() => {
  }, [])
  return (
    <div className={`WordFlat${exists ? ' exists' : ''}`}>
      {props.word[0] && <div className='w-2'></div>}
      <div>
        {props.word.map((letter: Letter) => <p key={letter.key} className={`inline ${commonLetters.includes(letter.str) ? 'common-letter' : ''}`}>{letter.str}</p>)}
      </div>
      {props.currentWord ? <div className='blinking-cursor w-2'>|</div> : <div className='w-2'></div>}

    </div >
  );
}

export default WordFlat;
