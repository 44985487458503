import React, { useEffect, useState } from 'react';
import axios from 'axios';

import PopupKeyItem from './PopupKeyItem';



function Keyboard(props: { writeDisabled?: boolean }) {
    let qwerty = 'qwertyuiopasdfghjklzxcvbnm'
    let qwerty_rows = ['qwertyuiop', 'asdfghjkl', 'zxcvbnm']

    return (
        <div className='Keyboard flex flex-col flex-wrap absolute bottom-5 items-center w-screen justify-center' style={{ width: '100%' }}>
            {/* <div className="flex flex-wrap justify-center">
                {qwerty.split('').map(letter => <PopupKeyItem letter={letter} spent={false} />)}
                <PopupKeyItem key='b_space' letter={'b_space'} spent={false} />
            </div> */}
            <div className="flex justify-center gap-1 flex-col">
                {qwerty_rows.map((row_letters, ind) => (
                    <div className='flex justify-center gap-1'>
                        {row_letters.split('').map(letter => <PopupKeyItem letter={letter} spent={props.writeDisabled === true} />)}
                        {ind === 2 && <PopupKeyItem key='b_space' letter={'b_space'} spent={false} />}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Keyboard;
