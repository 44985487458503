import React, { useEffect, useState } from 'react';
import './Loss.css';

import { useLetterStore, useHealthStore } from '../GameStore'



function Loss() {
  const [unusedLetters, writtenLetters, setNewLetters] = useLetterStore((state) => ([state.unusedLetters, state.writtenLetters, state.setNewLetters]))
  const [maxHealth, setMaxHealth] = useHealthStore((state) => ([state.maxHealth, state.setMaxHealth]))
  const [currentHealth, setCurrentHealth] = useHealthStore((state) => ([state.currentHealth, state.setCurrentHealth]))
  const [incomingDamage, setIncomingDamage] = useHealthStore((state) => ([state.incomingDamage, state.setIncomingDamage]))
  const [selectedItem, setSelectedItem] = useState('')

  useEffect(() => {

  }, [])
  return (
    <div className='Loss'>
      <div className='loss-content pt-5 pb-5'>
        <h1 className='text-2xl'>You Lost.</h1>
        <div>
          <p className='text-xl'>"what kills you makes you stronger"</p>
          <p>-frankensteins theory of relativity</p>
        </div>
        <button className='basic-button' onClick={() => {
          window.location.reload()
        }}>Try Again</button>
      </div>
    </div>
  );
}

export default Loss;
