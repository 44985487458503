import axios from 'axios';

const STARTING_HAND_SIZE = 8
const STARTING_MAX_TURNS = 5

const RARE_ITEM_THRESHOLD = 0.8
const LEGENDARY_ITEM_THRESHOLD = 0.98

// export const letterList = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

const dupe = (item: any, times: number) => {
    let array: any[] = []
    for (let i = 0; i < times; i++) {
        array.push(item)
    }
    return array
}

// const letterListByProbability = [...dupe('a', 8), ...dupe('b', 2), ...dupe('c', 4), ...dupe('d', 4), ...dupe('e', 12), ...dupe('f', 2), ...dupe('g', 3), ...dupe('h', 3), ...dupe('i', 9), ...dupe('j', 1), ...dupe('k', 1), ...dupe('l', 6), ...dupe('m', 3), ...dupe('n', 8), ...dupe('o', 7), ...dupe('p', 3), ...dupe('q', 1), ...dupe('r', 8), ...dupe('s', 9), ...dupe('t', 7), ...dupe('u', 4), ...dupe('v', 1), ...dupe('w', 1), ...dupe('x', 1), ...dupe('y', 2), ...dupe('z', 1)]
let letterListByProbability: string[] = [...dupe('a', 4), ...dupe('b', 1), ...dupe('c', 2), ...dupe('d', 2), ...dupe('e', 6), ...dupe('f', 1), ...dupe('g', 2), ...dupe('h', 2), ...dupe('i', 4), ...dupe('j', 1), ...dupe('k', 1), ...dupe('l', 3), ...dupe('m', 2), ...dupe('n', 4), ...dupe('o', 4), ...dupe('p', 2), ...dupe('q', 1), ...dupe('r', 4), ...dupe('s', 5), ...dupe('t', 4), ...dupe('u', 2), ...dupe('v', 1), ...dupe('w', 1), ...dupe('x', 1), ...dupe('y', 2), ...dupe('z', 1)]

//get word list and store it locally.
let getWordList = async () => {
    let resp = await axios.get(`${window.location.origin}/word_list.txt`)
    return resp.data
}

if (localStorage.getItem('wordList') === null) {
    getWordList().then((data) => localStorage.setItem('wordList', data))
}

let wordList = localStorage.getItem('wordList')?.split('\r\n')!
let wordSet = new Set(wordList)

class Item {
    name: string
    description: string
    icon: string
    rarity: string
    unique: boolean
    onAcquire: (text?: string) => void
    selectionPrompt: string | null
    text: string | null
    constructor(name: string, description: string, icon: string, rarity: string, unique: boolean, onAcquire: (text?: string) => void, selectionPrompt: string | null = null, text: string | null = null) {
        this.name = name
        this.description = description
        this.icon = icon
        this.rarity = rarity
        this.unique = unique
        this.onAcquire = onAcquire
        this.selectionPrompt = selectionPrompt
        this.text = text
    }
}

export class Potion {
    name: string
    description: string
    uses: number
    maxUses: number
    icon: string
    onUse: (gameState: GameState, text?: string) => void
    text: string | null
    constructor(name: string, description: string, maxUses: number, icon: string, onUse: (gameState: GameState, text?: string) => void, text: string | null = null) {
        this.name = name
        this.description = description
        this.maxUses = maxUses
        this.uses = maxUses
        this.icon = icon
        this.onUse = onUse
        this.text = text
    }
}

class Character {
    turns: number
    items: Item[]
    potions: Potion[]
    potionLimit: number
    potionExtraMaxUses: number
    wordLimit: number
    seeExists: boolean
    sortAlphabetically: boolean
    itemChoices: number
    wordComboMultiplier: number
    longWordMultiplier: number
    extraLetterModifier: number
    criticalChanceModifier: number
    criticalMultiplier: number
    potionDamageModifier: number
    rareLetterDamageModifier: number
    turnEndDamage: number
    startingLetters: Letter[]
    letterBaseDamage: { [str: string]: number }
    catchphrases: { [str: string]: number }
    refillPotionUses: boolean
    letterList: string[]

    turnDamageMultiplier: number
    constructor() {
        this.turns = STARTING_MAX_TURNS
        this.items = []
        this.potions = []
        this.potionLimit = 2
        this.potionExtraMaxUses = 0
        this.wordLimit = 1
        this.seeExists = false
        this.sortAlphabetically = true
        this.itemChoices = 3
        this.extraLetterModifier = 0
        this.criticalChanceModifier = 0
        this.wordComboMultiplier = 0
        this.longWordMultiplier = 0
        this.criticalMultiplier = 2
        this.potionDamageModifier = 1
        this.rareLetterDamageModifier = 1
        this.turnEndDamage = 0
        this.startingLetters = []
        this.letterBaseDamage = {
            'a': 1,
            'b': 1,
            'c': 1,
            'd': 1,
            'e': 1,
            'f': 1,
            'g': 1,
            'h': 1,
            'i': 1,
            'j': 3,
            'k': 1,
            'l': 1,
            'm': 1,
            'n': 1,
            'o': 1,
            'p': 1,
            'q': 3,
            'r': 1,
            's': 1,
            't': 1,
            'u': 1,
            'v': 1,
            'w': 2,
            'x': 2,
            'y': 1,
            'z': 2
        }
        this.letterList = letterListByProbability
        this.catchphrases = {}
        this.refillPotionUses = false

        this.turnDamageMultiplier = 1
    }
}


let increaseLetterDamage = (letters: string[], amount: number = 1): void => {
    letters.forEach((letter: string) => {
        character.letterBaseDamage[letter] += amount
    })
}
//reduce letter freq
//get 2 random items
//order abc
//start with letter, +separate item that makes that letter crit
//get potions - on defeat/special word (after special word item)/
//get gold for specific things
//some evil pact item that makes you lose a random letter's damage or something
//death save and consume item
const addItem = (item: Item, itemArray: Item[]): void => {
    if (!itemArray.includes(item)) {
        itemArray.push(item)
    }
}

let dagger = new Item('Wordsteel Dagger', 'Words deal +50% for each word in a row sharing a letter with the previous.', 'dagger.png', 'rare', false, () => character.wordComboMultiplier += 0.5)
let rubyWhetstone = new Item('Ruby Whetstone', 'Increase crit bonus damage by 50%.', 'stone_red.png', 'rare', false, () => character.criticalMultiplier += 0.5)

let commonItems: Item[] = [
    new Item('Torn Page', 'Get +2 extra letters per turn', 'page.png', 'common', false, () => character.extraLetterModifier += 2),
    new Item('Whetstone', '+10% chance for letter to be critical, dealing extra damage.', 'stone.png', 'common', false, () => { character.criticalChanceModifier += 0.1; addItem(rubyWhetstone, rareItems) }),
    new Item("Shining Star", 'Passively deal 1 damage each turn.', 'star.png', 'common', false, () => character.turnEndDamage++),
    new Item("Red Book", 'X,Z,V,H,U,T,I get +1 base damage.', 'book_red.png', 'common', false, () => increaseLetterDamage(['x', 'z', 'v', 'h', 'u', 't', 'i'])),
    new Item("Black Book", 'Q,J,F,M,D,O,A get +1 base damage.', 'book_black.png', 'common', false, () => increaseLetterDamage(['q', 'j', 'f', 'm', 'd', 'o', 'a'])),
    new Item("Orange Book", 'W,Y,P,C,N,S get +1 base damage.', 'book_orange.png', 'common', false, () => increaseLetterDamage(['w', 'y', 'p', 'c', 'n', 's'])),
    new Item("Green Book", 'K,B,G,L,R,E get +1 base damage.', 'book_green.png', 'common', false, () => increaseLetterDamage(['k', 'b', 'g', 'l', 'r', 'e'])),
    new Item("Fiendish Rune", "Choose a letter. It deals +2 damage.", 'rune.png', 'common', false, (text) => increaseLetterDamage([text!], 2), 'Choose a letter:'),
    new Item("Chemist's Cane", "Increase your damage by 10% for each potion you have.", 'cane.png', 'common', false, () => character.potionDamageModifier += 0.1),
    new Item("Esoteric Eyepatch", 'Each use of J,Q,X,Z increases word damage by 50%.', 'eyepatch.png', 'common', false, () => character.rareLetterDamageModifier += 0.5),
    new Item("Bag of Colding", 'Gain an extra potion slot.', 'bag.png', 'common', true, () => character.potionLimit++),
    new Item("Cheat Sheet", 'Choose a letter to make more common.', 'torn_paper.png', 'common', false, (text) => character.letterList.push(text!, text!), 'Choose a letter:'),
    new Item("Occam's Razor", 'Choose a letter to remove from the game.', 'razor.png', 'common', false, (text) => { character.letterList = character.letterList.filter((letter) => letter !== text!) }, 'Choose a letter:'), //needs to be reflected in ui. also should offer to choose 2 letters imo
]

let rareItems: Item[] = [
    new Item("Boot of Dilution", 'Potions you find have +1 max uses.', 'boot.png', 'rare', true, () => character.potionExtraMaxUses++),
    new Item('AR Goggles', 'See if written words exist before attacking.', 'goggles.png', 'rare', true, () => character.seeExists = true),
    // new Item('Cloak of The Order', 'Sort letters alphabetically.', 'cloak_purple.png', 'rare', true, () => character.sortAlphabetically = true),
    new Item('Ring of Verbosity', 'Increase words per turn limit by 1.', 'ring_blue.png', 'rare', false, () => { character.wordLimit++; addItem(dagger, rareItems) }),
    new Item('Grapes of Grandiloquence', 'Words deal +25% more damage for each letter after the 4th.', 'grapes.png', 'rare', false, () => character.longWordMultiplier += 0.25),
    new Item('Catchphrase Carrot', 'Choose a word (incl. nonexistent). It deals 150% more damage.', 'carrot.png', 'rare', false, (text?: string) => { character.catchphrases[text!] ? character.catchphrases[text!] += 1.5 : character.catchphrases[text!] = 2.5 }, 'Choose a catchphrase:'),
]

let legendaryItems: Item[] = [
    new Item('Candle', 'Item rewards contain an additional choice.', 'candle.png', 'legendary', true, () => character.itemChoices++),
    new Item('Magic Hourglass', 'Increase turn limit by 1.', 'hourglass.png', 'legendary', false, () => character.turns++),
    new Item('Book of Alchemy', 'Refill your potions after finishing a level.', 'book.png', 'legendary', true, () => character.refillPotionUses = true),
]

//reroll potion
let potions: Potion[] = [
    new Potion('Detox Juice', 'Draw a new hand of letters.', 3, 'potion_green.png', (gameState: GameState) => { getNewLetters(gameState) }),
    new Potion('Food Coloring', 'Deal +20% damage this turn.', 2, 'potion_purple.png', (gameState: GameState) => { character.turnDamageMultiplier += 0.2; gameState.refreshDamage++; }),
    new Potion('Holy Water', 'Choose a letter to add to your hand.', 3, 'potion_blue.png', (gameState: GameState, text) => { let letter = getLetter(text!, gameState.allLetters.length); gameState.unusedLetters.push(letter); gameState.allLetters.push(letter) }, 'Choose a letter:'),
    new Potion('Sands of Time', 'Increase your max turns by 1 this combat.', 1, 'potion_orange.png', (gameState: GameState) => { gameState.maxTurn++ })
]

function loadItems(character: Character) {
    //add unlocked items.
    const itemNameList = character.items.map(item => item.name)
    if (itemNameList.includes('Ring of Verbosity')) addItem(dagger, rareItems)
    if (itemNameList.includes('Whetstone')) addItem(rubyWhetstone, rareItems)

    //remove unique items, 
    const uniqueItems = character.items.filter(item => item.unique)
    uniqueItems.forEach(item => removeUniqueItem(item))
}

function loadPotions(character: Character) {
    const potionDict: { [potionName: string]: Potion } = potions.reduce((dict, potion) => ({ ...dict, [potion.name]: potion }), {})
    const characterLoadedPotions = [...character.potions]
    character.potions = []
    characterLoadedPotions.forEach(loadedPotion => {
        let potion = potionDict[loadedPotion.name]
        acquirePotion(character, { ...loadedPotion, onUse: potion.onUse })
    })
}

class Letter {
    key: number
    str: string
    damage: number
    critical: boolean
    constructor(key: number, str: string, damage: number, critical: boolean = false) {
        this.key = key
        this.str = str
        this.damage = damage
        this.critical = critical
    }
}

// character.items = [...commonItems, ...commonItems, ...rareItems]
// console.log(character.items)
// character.potions = potions

class GameState {
    unusedLetters: Letter[]
    allLetters: Letter[]
    writtenLetters: Letter[][]
    turn: number
    maxTurn: number
    level: number
    currentHealth: number
    maxHealth: number
    incomingDamage: number
    won: boolean
    refreshDamage: number //counter to increase whenever need to update damage state.
    constructor() {
        this.unusedLetters = []
        this.allLetters = []
        this.writtenLetters = [[]]
        this.turn = 0
        this.level = 0
        this.currentHealth = 0
        this.maxHealth = 0
        this.incomingDamage = 0
        this.won = false
        this.maxTurn = STARTING_MAX_TURNS
        this.refreshDamage = 0
    }
}

// const getRandomArrayItem = (array: Array<any>) => array[Math.floor(Math.random() * array.length)];


const getRandomArrayItem = (array: Array<any> | string, return_ind = false) => {
    let ind = Math.floor(Math.random() * array.length)
    return return_ind ? [array[ind], ind] : array[ind]
}


export const wordToString = (word: Letter[]): string => {
    let wordString = ''
    word.forEach((letter: Letter) => wordString += letter.str)
    return wordString
}

const wordStringExists = (word: string): boolean => {
    if (wordSet.size === 0) {
        wordSet = new Set(localStorage.getItem('wordList')?.split('\r\n')!)
    }
    return wordSet.has(word) || character.catchphrases[word] !== undefined;
}

const wordExists = (word: Letter[]): boolean => {
    let wordString = wordToString(word)
    return wordStringExists(wordString)
}

export const getCommonLetters = (word1: Letter[], word2: Letter[]) => {
    let word2String = wordToString(word2)
    let commonLetters: string[] = []
    for (const letter of word1) {
        if (word2String.includes(letter.str)) {
            return [letter.str]//commonLetters.push(letter.str)
        }
    }
    return commonLetters
}

const getWordDamage = (word: Letter[]): number => {
    const getRareLetterDamageMultiplier = (word: Letter[]): number => {
        let rareLetterCount = 0
        word.forEach(letter => {
            if ('jqxz'.includes(letter.str)) {
                rareLetterCount++
            }
        });
        return character.rareLetterDamageModifier ** rareLetterCount
    }
    let wordString = wordToString(word)
    let damage = 0
    word.forEach(letter => {
        damage += letter.damage
    });
    damage = damage * (1 + Math.max(word.length - 4, 0) * character.longWordMultiplier)//Add grape item damage
    if (character.catchphrases[wordString] !== undefined) {
        damage *= character.catchphrases[wordString]
    }
    damage *= character.potionDamageModifier ** character.potions.length
    damage *= getRareLetterDamageMultiplier(word)
    return damage
}

const getMultipleWordDamage = (words: Letter[][]): number => {
    let damage = character.turnEndDamage
    let combo = 0
    words.forEach((word, ind) => {
        if (ind > 0 && character.wordComboMultiplier > 0) {
            if (getCommonLetters(word, words[ind - 1]).length > 0) { //if any common letters
                combo++
            }
        }
        damage += getWordDamage(word) * character.turnDamageMultiplier * (1 + combo * character.wordComboMultiplier)
    });
    damage = Math.floor(damage)
    return damage
}


export const getItemChoices = () => {
    function loadItemChoices(loadedChoices: Item[]) {
        const loadedChoicesNames = loadedChoices.map(choice => choice.name)

        let itemChoices: Item[] = []
        let items = [...commonItems, ...rareItems, ...legendaryItems]
        items.forEach(item => {
            loadedChoicesNames.includes(item.name) && itemChoices.push(item)
        })
        return itemChoices
    }
    let storageItemChoices = localStorage.getItem('itemChoices')
    if (storageItemChoices) {
        let itemChoices = loadItemChoices(JSON.parse(storageItemChoices))
        return itemChoices
    }

    let choices: Item[] = []
    let commonItemOptions = [...commonItems]
    var rareItemOptions = [...rareItems]
    var legendaryItemOptions = [...legendaryItems]
    for (let i = 0; i < character.itemChoices; i++) {
        const rarityRoll = Math.random()
        let itemOptions: Item[]
        if (rarityRoll < RARE_ITEM_THRESHOLD || !rareItemOptions[0]) {
            itemOptions = commonItemOptions
        } else if (rarityRoll < LEGENDARY_ITEM_THRESHOLD || !legendaryItemOptions[0]) {
            itemOptions = rareItemOptions
        } else {
            itemOptions = legendaryItemOptions
        }

        let [item, itemInd] = getRandomArrayItem(itemOptions, true)
        item = { ...item }
        choices.push(item)
        itemOptions.splice(itemInd, 1)
    }

    localStorage.setItem('itemChoices', JSON.stringify(choices))
    return choices
}

function getItemIndex(item: Item, array: Item[]) {
    for (const [ind, arrayItem] of array.entries()) {
        if (arrayItem.name === item.name) return ind
    }
    return -1
}

function removeUniqueItem(item: Item) {
    let itemArray = item.rarity === 'common' ? commonItems : item.rarity === 'rare' ? rareItems : legendaryItems
    let ind = getItemIndex(item, itemArray)
    itemArray.splice(ind, 1)
}

const acquireItem = (item: Item, text: string | null = null): void => {
    localStorage.removeItem('itemChoices')
    if (item.unique) { //remove item from droplist if its unique
        removeUniqueItem(item)
    }
    if (text) {
        item.text = text
        item.onAcquire(text)
    }
    else {
        item.onAcquire()
    }
    character.items.push(item)
}

function acquirePotion(character: Character, potion: Potion) {
    if (character.potions.length >= character.potionLimit) return
    potion.maxUses += character.potionExtraMaxUses
    potion.uses += character.potionExtraMaxUses
    character.potions.push(potion)
}

export const consumePotion = (gameState: GameState, potion: Potion, text?: string) => {
    let ind = character.potions.indexOf(potion)
    character.potions[ind].uses -= 1
    if (character.potions[ind].uses === 0) character.potions.splice(ind, 1)
    text ? potion.onUse(gameState, text) : potion.onUse(gameState)
}

const getLetter = (str: string, key: number) => {
    let critical = Math.random() < character.criticalChanceModifier
    let damage = character.letterBaseDamage[str]
    if (critical) damage *= character.criticalMultiplier
    damage = Math.floor(damage)
    return new Letter(key, str, damage, critical)

}
const getRandomLetters = (amount: number): Letter[] => {
    let randomLetters: Letter[] = []
    for (let i: number = 0; i < amount; i++) {
        let str = getRandomArrayItem(character.letterList)
        randomLetters.push(getLetter(str, i))
    }
    return randomLetters
}
const getNewLetters = (gameState: GameState) => {
    let letters = [...getRandomLetters(STARTING_HAND_SIZE + character.extraLetterModifier)]
    let hasVowel = false
    for (let letter of letters) {
        if ('aoeiuy'.includes(letter.str)) {
            hasVowel = true
            break
        }
    }
    if (!hasVowel) {
        letters = [...letters.slice(0, -1), getLetter(getRandomArrayItem('aoeiuy'), letters.length - 1)]
    }
    gameState.unusedLetters = letters
    gameState.allLetters = [...letters]
    gameState.writtenLetters = [[]]
}

const startTurn = (gameState: GameState): void => {
    character.turnDamageMultiplier = 1
    getNewLetters(gameState)
    if (gameState.turn <= gameState.maxTurn) saveGame(gameState)
}

const getNewEnemy = (gameState: GameState): void => {
    gameState.level++
    if (character.refillPotionUses) {
        character.potions.forEach(potion => { potion.uses = potion.maxUses })
    }
    if (gameState.level % 3 === 0) acquirePotion(character, { ...getRandomArrayItem(potions) })
    gameState.turn = 1
    gameState.maxTurn = character.turns
    let maxHealth: number = Math.floor(4 + 2 * gameState.level + 1.30 ** gameState.level)// : Math.floor(gameState.level ** 2 - gameState.level * 7)
    gameState.maxHealth = maxHealth
    gameState.currentHealth = maxHealth
    gameState.won = false

    startTurn(gameState)
    // return gameState
}

const doTurn = (gameState: GameState): void => {
    // console.log('hi')
    if (gameState.turn > gameState.maxTurn) return
    gameState.currentHealth -= gameState.incomingDamage
    gameState.incomingDamage = 0

    // console.log(gameState.currentHealth)

    if (gameState.currentHealth <= 0) {
        gameState.won = true
        saveGame(gameState)
        return
    }
    gameState.turn++

    startTurn(gameState)
}

export function saveGame(gameState: GameState) {
    localStorage.setItem("character", JSON.stringify(character))
    localStorage.setItem("gameState", JSON.stringify(gameState))
}

export const loadGame = () => {
    let character = new Character()
    let gameState = new GameState()
    const characterSave = localStorage.getItem("character")
    const gameStateSave = localStorage.getItem("gameState")
    if (characterSave && gameStateSave) {
        try {
            character = JSON.parse(characterSave)
            gameState = JSON.parse(gameStateSave)
            loadItems(character)
            loadPotions(character)
        }
        catch (error) { //updating interface can break old game saves. need to delete them in that case instead of giving an error
            deleteSave()
            console.log(error)
            console.log('Error loading the game. Save deleted.')
            character = new Character()
            gameState = new GameState()
        }
    }
    return { character, gameState }
}

export function deleteSave() {
    localStorage.removeItem("character")
    localStorage.removeItem("gameState")
}


const character: Character = loadGame()["character"]


export { wordExists, GameState, Letter, Item, getNewEnemy, doTurn, getMultipleWordDamage, acquireItem, character }