import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './Background.css';

import { GameState, wordExists, getNewEnemy, doTurn } from '../GameLogic';
import { useLetterStore, useHealthStore } from '../GameStore'
import { maxHeaderSize } from 'http';
import { off } from 'process';

let tile_dict = {
  'wall_top_left': [16, 0, 16, 16],
  'wall_top_mid': [32, 0, 16, 16],
  'wall_top_right': [48, 0, 16, 16],
  'wall_left': [16, 16, 16, 16],
  'wall_mid': [32, 16, 16, 16],
  'wall_right': [48, 16, 16, 16],
  'wall_fountain_top': [64, 0, 16, 16],
  'wall_hole_1': [48, 32, 16, 16],
  'wall_hole_2': [48, 48, 16, 16],
  'wall_banner_red': [16, 32, 16, 16],
  'wall_banner_blue': [32, 32, 16, 16],
  'wall_banner_green': [16, 48, 16, 16],
  'wall_banner_yellow': [32, 48, 16, 16],
  'column_top': [80, 80, 16, 16],
  'column_mid': [80, 96, 16, 16],
  'coulmn_base': [80, 112, 16, 16],
  'wall_column_top': [96, 80, 16, 16],
  'wall_column_mid': [96, 96, 16, 16],
  'wall_coulmn_base': [96, 112, 16, 16],
  'wall_goo': [64, 80, 16, 16],
  'wall_goo_base': [64, 96, 16, 16],
  'floor_1': [16, 64, 16, 16],
  'floor_2': [32, 64, 16, 16],
  'floor_3': [48, 64, 16, 16],
  'floor_4': [16, 80, 16, 16],
  'floor_5': [32, 80, 16, 16],
  'floor_6': [48, 80, 16, 16],
  'floor_7': [16, 96, 16, 16],
  'floor_8': [32, 96, 16, 16],
  'floor_ladder': [48, 96, 16, 16],
  'wall_side_top_left': [0, 112, 16, 16],
  'wall_side_top_right': [16, 112, 16, 16],
  'wall_side_mid_left': [0, 128, 16, 16],
  'wall_side_mid_right': [16, 128, 16, 16],
  'wall_side_front_left': [0, 144, 16, 16],
  'wall_side_front_right': [16, 144, 16, 16],
  'wall_corner_top_left': [32, 112, 16, 16],
  'wall_corner_top_right': [48, 112, 16, 16],
  'wall_corner_left': [32, 128, 16, 16],
  'wall_corner_right': [48, 128, 16, 16],
  'wall_corner_bottom_left': [32, 144, 16, 16],
  'wall_corner_bottom_right': [48, 144, 16, 16],
  'wall_corner_front_left': [32, 160, 16, 16],
  'wall_corner_front_right': [48, 160, 16, 16],
  'wall_inner_corner_l_top_left': [80, 128, 16, 16],
  'wall_inner_corner_l_top_rigth': [64, 128, 16, 16],
  'wall_inner_corner_mid_left': [80, 144, 16, 16],
  'wall_inner_corner_mid_rigth': [64, 144, 16, 16],
  'wall_inner_corner_t_top_left': [80, 160, 16, 16],
  'wall_inner_corner_t_top_rigth': [64, 160, 16, 16],
  'edge': [96, 128, 16, 16],
  'hole': [96, 144, 16, 16],
  'doors_all': [16, 221, 64, 35],
  'doors_frame_left': [16, 224, 16, 32],
  'doors_frame_top': [32, 221, 32, 3],
  'doors_frame_righ': [63, 224, 16, 32],
  'doors_leaf_closed': [32, 224, 32, 32],
  'doors_leaf_open': [80, 224, 32, 32],
  'flask_big_red': [288, 224, 16, 16],
  'flask_big_blue': [304, 224, 16, 16],
  'flask_big_green': [320, 224, 16, 16],
  'flask_big_yellow': [336, 224, 16, 16],
  'flask_red': [288, 240, 16, 16],
  'flask_blue': [304, 240, 16, 16],
  'flask_green': [320, 240, 16, 16],
  'flask_yellow': [336, 240, 16, 16],
  'skull': [288, 320, 16, 16],
  'crate': [288, 298, 16, 22],
  'ui_heart_full': [288, 256, 16, 16],
  'ui_heart_half': [304, 256, 16, 16],
  'ui_heart_empty': [320, 256, 16, 16],
  'weapon_knife': [293, 18, 6, 13],
  'weapon_rusty_sword': [307, 26, 10, 21],
  'weapon_regular_sword': [323, 26, 10, 21],
  'weapon_red_gem_sword': [339, 26, 10, 21],
  'weapon_big_hammer': [291, 42, 10, 37],
  'weapon_hammer': [307, 55, 10, 24],
  'weapon_baton_with_spikes': [323, 57, 10, 22],
  'weapon_mace': [339, 55, 10, 24],
  'weapon_katana': [293, 82, 6, 29],
  'weapon_saw_sword': [307, 86, 10, 25],
  'weapon_anime_sword': [322, 81, 12, 30],
  'weapon_axe': [341, 90, 9, 21],
  'weapon_machete': [294, 121, 5, 22],
  'weapon_cleaver': [310, 124, 8, 19],
  'weapon_duel_sword': [325, 113, 9, 30],
  'weapon_knight_sword': [339, 114, 10, 29],
  'weapon_golden_sword': [291, 153, 10, 22],
  'weapon_lavish_sword': [307, 145, 10, 30],
  'weapon_red_magic_staff': [324, 145, 8, 30],
  'weapon_green_magic_staff': [340, 145, 8, 30],
  'weapon_spear': [293, 177, 6, 30],
  'weapon_arrow': [308, 186, 7, 21],
  'weapon_bow': [325, 180, 7, 25],
  'chest': [304, 288, 16, 16],
  'chest_full_open_anim': [352, 304, 16, 16],
}
let animations = {
  'chest_empty_open_anim': [304, 288, 16, 16, 3],
  'chest_full_open_anim': [304, 304, 16, 16, 3],
  'chest_mimic_open_anim': [304, 320, 16, 16, 3],
  'tiny_zombie_idle_anim': [368, 16, 16, 16, 4],
  'tiny_zombie_run_anim': [432, 16, 16, 16, 4],
  'goblin_idle_anim': [368, 32, 16, 16, 4],
  'goblin_run_anim': [432, 32, 16, 16, 4],
  'imp_idle_anim': [368, 48, 16, 16, 4],
  'imp_run_anim': [432, 48, 16, 16, 4],
  'skelet_idle_anim': [368, 80, 16, 16, 4],
  'skelet_run_anim': [432, 80, 16, 16, 4],
  'muddy_idle_anim': [368, 112, 16, 16, 4],
  'muddy_run_anim': [368, 112, 16, 16, 4],
  'swampy_idle_anim': [432, 112, 16, 16, 4],
  'swampy_run_anim': [432, 112, 16, 16, 4],
  'zombie_idle_anim': [368, 144, 16, 16, 4],
  'zombie_run_anim': [368, 144, 16, 16, 4],
  'ice_zombie_idle_anim': [432, 144, 16, 16, 4],
  'ice_zombie_run_anim': [432, 144, 16, 16, 4],
  'masked_orc_idle_anim': [368, 172, 16, 20, 4],
  'masked_orc_run_anim': [432, 172, 16, 20, 4],
  'orc_warrior_idle_anim': [368, 204, 16, 20, 4],
  'orc_warrior_run_anim': [432, 204, 16, 20, 4],
  'orc_shaman_idle_anim': [368, 236, 16, 20, 4],
  'orc_shaman_run_anim': [432, 236, 16, 20, 4],
  'necromancer_idle_anim': [368, 268, 16, 20, 4],
  'necromancer_run_anim': [368, 268, 16, 20, 4],
  'wogol_idle_anim': [368, 300, 16, 20, 4],
  'wogol_run_anim': [432, 300, 16, 20, 4],
  'chort_idle_anim': [368, 328, 16, 24, 4],
  'chort_run_anim': [432, 328, 16, 24, 4],
  'big_zombie_idle_anim': [16, 270, 32, 34, 4],
  'big_zombie_run_anim': [144, 270, 32, 34, 4],
  'ogre_idle_anim': [16, 320, 32, 32, 4],
  'ogre_run_anim': [144, 320, 32, 32, 4],
  'big_demon_idle_anim': [16, 364, 32, 36, 4],
  'big_demon_run_anim': [144, 364, 32, 36, 4],
  'elf_f_idle_anim': [128, 4, 16, 28, 4],
  'elf_f_run_anim': [192, 4, 16, 28, 4],
  'elf_f_hit_anim': [256, 4, 16, 28, 1],
  'elf_m_idle_anim': [128, 36, 16, 28, 4],
  'elf_m_run_anim': [192, 36, 16, 28, 4],
  'elf_m_hit_anim': [256, 36, 16, 28, 1],
  'knight_f_idle_anim': [128, 68, 16, 28, 4],
  'knight_f_run_anim': [192, 68, 16, 28, 4],
  'knight_f_hit_anim': [256, 68, 16, 28, 1],
  'knight_m_idle_anim': [128, 100, 16, 28, 4],
  'knight_m_run_anim': [192, 100, 16, 28, 4],
  'knight_m_hit_anim': [256, 100, 16, 28, 1],
  'wizard_f_idle_anim': [128, 132, 16, 28, 4],
  'wizard_f_run_anim': [192, 132, 16, 28, 4],
  'wizard_f_hit_anim': [256, 132, 16, 28, 1],
  'wizard_m_idle_anim': [128, 164, 16, 28, 4],
  'wizard_m_run_anim': [192, 164, 16, 28, 4],
  'wizard_m_hit_anim': [256, 164, 16, 28, 1],
  'lizard_f_idle_anim': [128, 196, 16, 28, 4],
  'lizard_f_run_anim': [192, 196, 16, 28, 4],
  'lizard_f_hit_anim': [256, 196, 16, 28, 1],
  'lizard_m_idle_anim': [128, 228, 16, 28, 4],
  'lizard_m_run_anim': [192, 228, 16, 28, 4],
  'lizard_m_hit_anim': [256, 228, 16, 28, 1],

}

const floorConditions = [tile_dict['floor_1'], tile_dict['floor_5'], tile_dict['floor_3'], tile_dict['floor_2'], tile_dict['floor_4']]

export let gameAnimation = {
  attack: false,
  chestOpen: false,
  floorCondition: 1,
  pan_x: 0
}


function Background() {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  let intervalId: NodeJS.Timer | null = null;

  // const canvas: HTMLCanvasElement | null = canvasRef.current
  // const context: CanvasRenderingContext2D | null = canvasRef.current ? canvasRef.current.getContext('2d') : null
  // if (context) {
  //   const context = canvas?.getContext('2d')

  // }
  let img = new Image;
  img.src = '/tileset/tileset.png';



  const drawGame = () => {
    const drawAnimation = (animation: number[], loc: number[], frame: number) => {
      if (loc[0] > width || loc[1] > height) {
        return
      }

      if (animation.length === 4) drawTile(animation, loc)

      const [x, y, w, h, f] = animation
      let sprite = frame % f;
      context.drawImage(img, x + sprite * w, y, w, h, loc[0], loc[1], w * scale, h * scale)
    }

    const drawTile = (tile: number[], loc: number[]) => {
      const [x, y, w, h] = tile
      context.drawImage(img, x, y, w, h, loc[0], loc[1], w * scale, h * scale)

    }


    // const drawCharacter = (frame: number) => {
    //   // let sprite = frame % 4;
    //   // let scale = 3
    //   // let width = 16
    //   // let height = 28
    //   // context.drawImage(img, 128 + sprite * width, 132, width, height, 130, 130, width * scale, height * scale)
    //   drawAnimation(animations['wizard_f_idle_anim'], [500, 699], frame)
    // }

    const drawBackground = (offsetX: number) => {
      let offsetDrawn = offsetX % tileSize
      context.fillRect(0, 0, width, height)
      for (let x = 0; x < tilesX + 1; x++) {
        for (let y = 1; y < tilesY - 2; y++) {
          let tile = tile_dict['floor_1']
          if (x * tileSize < levelFloor - offsetX) {
            if (gameAnimation.floorCondition >= floorConditions.length) continue
            tile = floorConditions[gameAnimation.floorCondition]
          }

          if (y === 1) tile = tile_dict['wall_banner_blue']

          drawTile(tile, [x * tileSize - offsetDrawn, y * tileSize])
        }
      }
    }
    const scale = 3
    const pan_rate = 18

    const canvas = canvasRef.current!
    const context = canvas.getContext('2d')!
    const width = canvas.clientWidth
    const height = canvas.clientHeight
    canvas.width = width
    canvas.height = height

    const tileSize = 48
    const [tilesX, tilesY] = [width / tileSize, height / tileSize]
    context.fillStyle = '#361030'
    context.fillRect(0, 0, width, height)
    // console.log(context)
    // context.imageSmoothingEnabled = false;
    // context.imageSmoothingQuality = 'high';
    // context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    // context.drawImage(img, 0, 0, img.height, img.width)
    // context.drawImage(img, 16, 64, 16, 16, 0, 0, 16, 16)

    // for (let x = 5; x < tilesX - 2; x++) {
    //   for (let y = 2; y < tilesY; y++) {
    //     context.drawImage(img, 16, 64, 16, 16, x * tileSize, y * tileSize, tileSize, tileSize)
    //   }
    // }
    // context.drawImage(img, 16, 64, 16, 16, 0, 0, 48, 48)
    console.log(context.canvas.width, context.canvas.height)

    let character_location = [width / 3 - tileSize, height / 2 - tileSize]

    let frame = 0
    let offsetX = 0

    let levelFloor = width / 2

    let chestCount = 0
    let chestAnimation: [number[], number] = [tile_dict['chest'], 0] //animation, frame
    intervalId && clearInterval(intervalId)
    intervalId = setInterval(() => {
      frame++
      if (gameAnimation.pan_x > 0) {
        let frame_pan = Math.min(pan_rate, gameAnimation.pan_x)
        gameAnimation.pan_x -= frame_pan
        offsetX += frame_pan
      }
      else if (gameAnimation.chestOpen) {
        chestAnimation = [animations['chest_full_open_anim'], 0]
        gameAnimation.chestOpen = false
      }

      let pan_x = gameAnimation.pan_x

      if (gameAnimation.floorCondition > 0 && offsetX > levelFloor) {
        gameAnimation.floorCondition = 0
        levelFloor += width
      }

      drawBackground(offsetX)
      if (gameAnimation.floorCondition >= floorConditions.length) clearInterval(intervalId!)
      // let character_animation = animations['wizard_f_idle_anim']
      let character_animation = pan_x > 0 ? animations['wizard_f_run_anim'] : animations['wizard_f_idle_anim']

      if (gameAnimation.attack) {
        gameAnimation.attack = false
        frame = 0
        character_animation = animations['wizard_f_hit_anim']
      }


      // let chestFrequency = offsetX % tileSize
      // drawTile(tile_dict['chest'], [width * 2 / 3 - offsetX, height / 2 - 12])

      //for width/chest frequency
      //draw chest
      //if behind character -> open
      let chest_offset = width * 2 / 3
      let chest_frequency = width

      //chest
      if (chestAnimation[0].length === 5) {
        if (chestAnimation[1] < chestAnimation[0][4] - 1) {
          chestAnimation[1]++
        }
        if (offsetX % width > chest_offset + tileSize) { //reset chest to closed if out of view
          chestAnimation[0] = tile_dict['chest']
        }
      }
      for (let ci = 0; ci < (width / chest_frequency) + 1; ci++) {
        if (ci === 0) {
          drawAnimation(chestAnimation[0], [chest_offset - offsetX % chest_frequency, height / 2 - 12], chestAnimation[1])
          continue
        }
        drawAnimation(tile_dict['chest'], [chest_frequency * ci - offsetX % chest_frequency + chest_offset, height / 2 - 12], frame)
      }

      drawAnimation(character_animation, character_location, frame)
    }, 100)
  }



  useEffect(() => {
    drawGame()
  }, [])


  return (
    // <div style={{ backgroundColor: '#361030', zIndex: -5, width: '100%', height: '100%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
    // </div>
    <canvas id="background" className="Background" ref={canvasRef}>

    </canvas >
  );
}

export default Background;
