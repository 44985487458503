import React, { useState } from 'react';
import './Potions.css';
import PotionDisplay from './PotionDisplay/PotionDisplay';
import { character, GameState, Potion } from '../GameLogic';


// import { useHealthStore } from '../../GameStore'

function Potions(props: { gameState: GameState, updateState: () => void }) {

  return (
    <div className="Potions">
      {/* {character.items.map((item) => <Potion item={item} />)} */}
      {character.potions.map((potion: Potion, ind) => <PotionDisplay key={ind} potion={potion} gameState={props.gameState} updateState={props.updateState} />)}
      {[...Array(character.potionLimit - character.potions.length)].map((potion: Potion, ind) => <PotionDisplay key={ind} gameState={props.gameState} updateState={props.updateState} />)}
    </div >
  );
}

export default Potions;
