import React, { useState } from 'react';
import './TopHud.css';

import HealthBar from './HealthBar/HealthBar';
import { useLetterStore, useHealthStore, useGameInfoStore } from '../GameStore'



function TopHud() {

  let [turn, maxTurn, level, setTurn, setMaxTurn, setLevel] = useGameInfoStore((state) => ([state.turn, state.maxTurn, state.level, state.setTurn, state.setMaxTurn, state.setLevel]))


  return (
    <div className="TopHud" style={{ width: 400, position: 'absolute' }}>
      <div className='level-info' style={{ display: 'flex', columnGap: 5 }}>
        <label>Level {level}</label>
      </div>
      <div className='turn-info' style={{ display: 'flex', columnGap: 5 }}>
        <label>Turn</label>
        <div className='turn-counter'>
          <span>{turn}</span>
          <span>/</span>
          <span>{maxTurn}</span>
        </div>
      </div>
      <HealthBar />
    </div>
  );
}

export default TopHud;
