import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Letter, wordExists, character, wordToString } from '../GameLogic';
import { useLetterStore } from '../GameStore';
import './WordDisp.css'
// import Word from './Word/Word';
import WordFlat from './WordFlat/WordFlat';

function WordDisp() {
    const [words, setWords] = useState(Array<Array<Letter>>())

    let [writtenLetters] = useLetterStore((state) => ([state.writtenLetters,]))

    useEffect(() => {
        setWords(writtenLetters);

    }, [writtenLetters])

    // function renderWords() {
    //     const jsx = words.map((word, wordInd) => {
    //         // let exists = character.seeExists && word[0] && wordExists(word)
    //         return word.map((letter, letterInd) => {
    //             if (letterInd === 0 && wordInd !== 0)
    //                 if (word[letterInd] === undefined) {
    //                     <p>&nbsp;</p>
    //                 }
    //                 else {
    //                     return (<>
    //                         <p>&nbsp; {letter.str}</p>
    //                     </>)
    //                 }

    //             else {
    //                 return <p style={exists ? { color: 'green' } : {}}>{letter.str}</p>
    //             }
    //         })
    //     })

    //     // console.log(jsx)

    //     return jsx;
    // }

    return (
        <div className='WordDisp flex text-xl font-bold select-none flex-col items-center'>
            {/* {writtenLetters.map((letterArray: Letter[], ind) => <Word key={ind} word={letterArray} currentWord={ind === writtenLetters.length - 1} />)} */}
            {writtenLetters.map((letterArray: Letter[], ind) => <WordFlat key={ind} word={letterArray} currentWord={ind === writtenLetters.length - 1} previousWord={ind > 0 ? writtenLetters[ind - 1] : []} />)}
        </div>
    );
}

export default WordDisp;
