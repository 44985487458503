import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './InputPopup.css';

import { GameState } from '../GameLogic';
import { useOverlayKeyboardStore } from '../GameStore'
import PopupKeyItem from '../PopupKeyboard/PopupKeyItem';
import PopupKeyboard from '../PopupKeyboard/PopupKeyboard';



function InputPopup(props: { prompt: string, gameState?: GameState, onContinue: (text: string, gameState?: GameState) => void }) {
  let [text, eraseText] = useOverlayKeyboardStore((state) => ([state.text, state.eraseText]))

  let limitOne = props.prompt === 'Choose a letter:'; //limit to one letter

  useEffect(() => {

  }, [])
  return (
    <div className='InputPopup'>
      <div className='input-popup-content'>
        <div className='flex flex-col items-center'>
          <h1 className='title'>{props.prompt}</h1>
          <div className="flex items-center" style={{ height: 40 }}>
            {text.split('').map((letter) =>
              <PopupKeyItem key={letter} letter={letter} spent={false} write={false} />
            )}

          </div>
        </div>
        <button className='basic-button' onClick={() => {
          if (text === '') { return }
          eraseText()
          props.onContinue(text)
        }}>Continue</button>
        <PopupKeyboard writeDisabled={limitOne && text.length > 0} />
      </div>
    </div>
  );
}

export default InputPopup;
