import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import ItemChoice from './ItemChoice/ItemChoice';
import './ItemSelection.css';

import { getItemChoices, Item, acquireItem } from '../GameLogic';
import { useLetterStore, useHealthStore } from '../GameStore'
import ItemPopup from './ItemPopup/ItemPopup';


interface ItemSelectionProps {
  newRound: () => void
}

function ItemSelection(props: ItemSelectionProps) {
  // const [selectedItem, setSelectedItem] = useState('')
  const [popup, setPopup] = useState(false)

  const [itemChoices, _] = useState(getItemChoices())
  const [selectedItem, setSelectedItem] = useState(itemChoices[0].name)

  // setSelectedItem(itemChoices[0].name)
  useEffect(() => {

  }, [])
  return (
    <div className='ItemSelection'>
      {popup && <ItemPopup item={itemChoices.filter((item: Item) => item.name === selectedItem)[0]} newRound={props.newRound} />}
      {!popup && <>
        <h1 className='title'>Choose A Reward:</h1>
        {itemChoices.map(((item: Item) => <ItemChoice key={item.name} item={item} selected={item.name === selectedItem} setSelectedItem={setSelectedItem} />))}
        <button className='choose-item basic-button' onClick={() => {
          if (!selectedItem) return
          let item = itemChoices.filter((item: Item) => item.name === selectedItem)[0]
          if (item.selectionPrompt !== null) {
            setPopup(true)
            return
          }
          acquireItem(item)
          props.newRound()
        }}>Continue</button>
      </>}
    </div>
  );
}

export default ItemSelection;
