import { useState, useEffect } from 'react';
import { cursorTo } from 'readline';
import { useOverlayKeyboardStore } from '../GameStore';
// import { Letter, letterList } from '../GameLogic';


function PopupKeyItem(props: { letter: string, spent: boolean, write?: boolean }) {
    const { letter, spent } = props;
    let [text, write, eraseLetter] = useOverlayKeyboardStore((state) => ([state.text, state.write, state.eraseLetter]))

    const classNameBySpent = spent ? 'spent bg-zinc-500 ' : 'bg-white';
    const basicDesign = 'PopupKeyItem border-2 border-gray-800 px-3 py-1 rounded-md font-mono text-xl' + (props.write === false ? '' : ' cursor-pointer')

    const backspaceSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
    </svg>

    function renderByStrVal() {
        switch (letter) {
            case 'b_space':
                return (
                    <span key="b_space" className={`${basicDesign} flex items-center justify-center bg-white`}
                        onClick={() => {
                            eraseLetter();
                        }}>
                        {backspaceSvg}
                    </span>
                )

            default:
                return (
                    <span key={letter} className={`${basicDesign} ${classNameBySpent} relative select-none`}
                        onClick={() => {
                            if (!spent && props.write !== false) {
                                write(letter);
                            }
                        }}>
                        <p>{letter}</p>
                    </span>
                );
        }
    }

    return (
        <>
            {renderByStrVal()}
        </>
    )
}

export default PopupKeyItem
