import React, { useState } from 'react';
import './OwnedItems.css';
import OwnedItem from '../OwnedItem/OwnedItem';
import { character, Item } from '../GameLogic';


// import { useHealthStore } from '../../GameStore'

function OwnedItems() {
  interface GroupedItem {
    amount: number
    item: Item
  }

  let groupedItems: { [name: string]: GroupedItem } = {}
  let ungroupedItems: Item[] = []
  character.items.forEach((item) => {
    if (item.text) {
      ungroupedItems.push(item)
    }
    else if (item.name in groupedItems) {
      groupedItems[item.name].amount++
    }
    else {
      groupedItems[item.name] = { amount: 1, item }
    }
  })

  const jsx = []

  for (const [k, v] of Object.entries(groupedItems)) {
    jsx.push(<OwnedItem key={v.item.name} item={v.item} amount={v.amount} />)
  }

  ungroupedItems.forEach((item, ind) => {
    jsx.push(<OwnedItem key={`${item.name}${ind}`} item={item} amount={1} />)
  })

  return (
    <div className="OwnedItems">
      {jsx}
    </div >
  );
}

export default OwnedItems;
