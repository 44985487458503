import { useState, useEffect } from 'react';
import { useLetterStore } from '../GameStore'
import { Letter } from '../GameLogic';


function KeyItem(props: { letter: Letter, spent: boolean }) {
    const { letter, spent } = props;
    const classNameBySpent = spent ? 'spent bg-zinc-500 ' : 'hover:cursor-pointer bg-white';
    const classNameByCrit = letter.critical && !spent ? 'bg-red-500' : '';
    const basicDesign = 'KeyItem border-2 border-gray-800 px-4 py-2 mx-1 rounded-md font-mono text-xl m-1 hover:cursor-pointer'

    const backspaceSvg = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
    </svg>

    // ternary to add appropriate className to crit characters
    let [
        writeLetter,
        eraseLetter,
        addSpace,] = useLetterStore(
            (state) => (
                [
                    state.writeLetter,
                    state.eraseLetter,
                    state.addSpace,]))

    function renderByStrVal() {
        switch (letter.str) {
            case 'b_space':
                return (
                    <span key="b_space" className={`${basicDesign} flex items-center justify-center bg-white`}
                        onClick={() => {
                            eraseLetter();
                        }}>
                        {backspaceSvg}
                    </span>
                )

            case 'enter':
                return (
                    <span key="enter" className={`${basicDesign} ${classNameBySpent}`}
                        onClick={() => {
                            if (!spent) {
                                addSpace()
                            }
                        }}>
                        <p>Enter</p>
                    </span>
                )

            default:
                return (
                    <span key={letter.key} className={`${basicDesign} ${classNameBySpent} ${classNameByCrit} relative select-none`}
                        onClick={() => {
                            if (!spent) {
                                writeLetter(letter);
                            }
                        }}>
                        <p>{letter.str}</p>
                        <span className={`absolute top-0 right-1 text-sm`}>{letter.damage}</span>
                    </span>
                );
        }
    }

    return (
        <>
            {renderByStrVal()}
        </>
    )
}

export default KeyItem
