import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './Game.css';


import TopHud from '../TopHud/TopHud';
import Keyboard from '../Keyboard/Keyboard';
import { GameState, getNewEnemy, getMultipleWordDamage, loadGame, deleteSave, saveGame } from '../GameLogic';
import ItemSelection from '../ItemSelection/ItemSelection';
import OwnedItems from '../OwnedItems/OwnedItems';
import { useLetterStore, useHealthStore, useGameInfoStore } from '../GameStore'
import { gameAnimation } from '../Background/Background';
import Loss from '../Loss/Loss';
import Tutorial from '../Tutorial/Tutorial';
import Potions from '../Potions/Potions';


const { gameState } = loadGame()

function Game() {
  let [unusedLetters, writtenLetters, setUnusedLetters, setAllLetters, setNewLetters] = useLetterStore((state) => ([state.unusedLetters, state.writtenLetters, state.setUnusedLetters, state.setAllLetters, state.setNewLetters]))
  // console.log(wordExists('doggies'))
  let [maxHealth, setMaxHealth] = useHealthStore((state) => ([state.maxHealth, state.setMaxHealth]))
  let [currentHealth, setCurrentHealth] = useHealthStore((state) => ([state.currentHealth, state.setCurrentHealth]))
  let [incomingDamage, setIncomingDamage] = useHealthStore((state) => ([state.incomingDamage, state.setIncomingDamage]))
  let [turn, maxTurn, level, setTurn, setMaxTurn, setLevel] = useGameInfoStore((state) => ([state.turn, state.maxTurn, state.level, state.setTurn, state.setMaxTurn, state.setLevel]))

  const [refreshDamage, setRefreshDamage] = useState(0)
  const [won, setWon] = useState(false)

  let updateState = (): void => {
    setCurrentHealth(gameState.currentHealth)
    setMaxHealth(gameState.maxHealth)
    setIncomingDamage(gameState.incomingDamage)
    setTurn(gameState.turn)
    setMaxTurn(gameState.maxTurn)
    setLevel(gameState.level)
    setRefreshDamage(gameState.refreshDamage)
    // gameState.refreshDamage = false
    if (gameState.writtenLetters[0][0] === undefined) {
      setNewLetters(gameState.unusedLetters)
    }
    else {
      setUnusedLetters(gameState.unusedLetters)
      setAllLetters(gameState.allLetters)
    }
    setWon(gameState.won)
  }

  useEffect(() => {
    if (gameState.level === 0) getNewEnemy(gameState)
    updateState()
    // console.log(gameState)
  }, [])

  useEffect(() => {
    if (won) {
      gameAnimation.pan_x = document.getElementById('background')!.clientWidth / 3
      gameAnimation.chestOpen = true
    }
  }, [won])


  useEffect(() => {
    let newIncomingDamage = getMultipleWordDamage(writtenLetters)
    setIncomingDamage(newIncomingDamage)
    gameState.incomingDamage = newIncomingDamage

  }, [writtenLetters, refreshDamage])

  const newRound = () => {
    getNewEnemy(gameState)
    gameAnimation.pan_x = 2 * document.getElementById('background')!.clientWidth / 3
    updateState()

  }

  useEffect(() => {
    if (turn > maxTurn) deleteSave()

  }, [turn])


  return (
    <div className="Game" id="Game" onKeyUp={(e) => console.log(e.key)}>
      {/* <div className="Game" style={{ backgroundImage: "url('/bg.jpg')", backgroundSize: 'cover' }}> */}
      {won && <ItemSelection newRound={newRound} />}
      {level === 1 && turn === 1 && !won && <Tutorial />}
      {turn > maxTurn && <Loss />}
      <OwnedItems />
      <Potions gameState={gameState} updateState={updateState} />
      <TopHud />
      {/* {won && <div className='h-screen flex justify-items-center items-center text-lg'>yu won zulul</div>} */}
      {/* <img key={level} style={{ maxHeight: '50vh' }} className='mt-32' src={`https://thispersondoesnotexist.com/image?l=${level}`} /> */}
      <Keyboard gameState={gameState} updateState={updateState} />
      {/* <AttackButton gameState={gameState} updateState={updateState} /> */}
    </div>
  );
}

export default Game;
