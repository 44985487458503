import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Letter, character, wordToString, GameState } from '../GameLogic';
import { useLetterStore, useHealthStore, useGameInfoStore } from '../GameStore'
import './Keyboard.css'

import KeyItem from './KeyItem';
import WordDisp from '../WordDisp/WordDisp';
import { clearConfigCache } from 'prettier';
import AttackButton from '../AttackButton/AttackButton';


interface AttackButtonProps {
    gameState: GameState
    updateState: () => void
}


function Keyboard(props: AttackButtonProps) {

    let [unusedLetters,
        writtenLetters,
        allLetters,
        writeLetter,
        eraseLetter,
        addSpace,
        setNewLetters] = useLetterStore((state) => ([state.unusedLetters, state.writtenLetters, state.allLetters, state.writeLetter, state.eraseLetter, state.addSpace, state.setNewLetters]))

    const [used, setUsed] = useState(Array<Letter>());
    const [unused, setUnused] = useState(Array<Letter>());

    function getUsedLettersFromWritten() {
        let usedLetters = Array<Letter>();
        writtenLetters.forEach(word => {
            word.forEach(letter => {
                usedLetters.push(letter);
            })
        })

        return usedLetters;
    }

    // function getUsedKeys() {
    //     let keys = Array();
    //     used.forEach((l) => {
    //         keys.push(l.key)
    //     })

    //     return keys;
    // }


    const keySort = (a: { str: string, orderKey: number }, b: { str: string, orderKey: number }) => { return a.orderKey - b.orderKey }
    const abcSort = (a: { str: string, orderKey: number }, b: { str: string, orderKey: number }) => { return a.str.localeCompare(b.str) || a.orderKey - b.orderKey }
    const letterSortFunction = character.sortAlphabetically ? abcSort : keySort//alphabetically or not


    useEffect(() => {
        setUnused(allLetters)
    }, [])

    useEffect(() => {
        const usedArr = getUsedLettersFromWritten();
        setUsed(usedArr)
        setUnused(unusedLetters)
    }, [unusedLetters])


    let keyType = (e: KeyboardEvent) => {
        if (e.code === 'Backspace') {
            eraseLetter()
            return
        }
        if (e.code === 'Enter' && character.wordLimit > writtenLetters.length && writtenLetters.slice(-1)[0][0] !== undefined) {
            addSpace()
            return
        }


        //prio crit. if uppercase prio non-crit
        let popInd = 0;
        if ('QWERTYUIOPASDFGHJKLZXCVBNM'.includes(e.key)) {
            popInd = -1;
        }
        else if (!'qwertyuiopasdfghjklzxcvbnm'.includes(e.key)) return //if not a letter, return.

        let matchingLetters = unused.filter((letter) => letter.str === e.key.toLowerCase())
        if (matchingLetters.length === 0) return

        let critSorted = matchingLetters.sort((a, b) => Number(b.critical) - Number(a.critical))
        writeLetter(critSorted.slice(popInd)[0])
    }

    useEffect(() => {
        //typing
        document.addEventListener('keyup', keyType, true);
        return () => {
            document.removeEventListener('keyup', keyType, true);
        }
    }, [unused])


    function orderLettersByKey() {

        const usedLettersJSX = used.map((letter: Letter) => {
            return { jsx: <KeyItem key={letter.key} letter={letter} spent={true} />, orderKey: letter.key, str: letter.str }
        })

        const unusedLettersJSX = unused.map((letter: Letter) => {
            return { jsx: <KeyItem key={letter.key} letter={letter} spent={false} />, orderKey: letter.key, str: letter.str }
        })


        const sortedJsxElements = ([...usedLettersJSX, ...unusedLettersJSX].sort(letterSortFunction))

        return (sortedJsxElements.map((jsxElem) => {
            return jsxElem.jsx;
        }))
    }

    const density = allLetters.length < 14 ? '' : allLetters.length < 20 ? 'dense' : 'extra-dense'



    return (
        <div className={`Keyboard flex flex-col flex-wrap absolute bottom-5 items-center w-screen justify-center z-10 ${density}`} style={{ width: '100%' }}>
            <div className='flex' style={{ width: '95%' }}>
                <div style={{ width: 45 }}></div>
                <WordDisp />
                <AttackButton gameState={props.gameState} updateState={props.updateState} />
            </div>
            <div className="flex flex-wrap justify-center">

                {
                    orderLettersByKey()
                }

                <KeyItem key='b_space' letter={new Letter(999, 'b_space', 0)} spent={false} />
                {character.wordLimit > 1 && <KeyItem key='enter' letter={new Letter(998, 'enter', 0)} spent={character.wordLimit <= writtenLetters.length || writtenLetters.slice(-1)[0][0] === undefined} />}
            </div>

        </div>
    );
}

export default Keyboard;
