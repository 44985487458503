import React, { useEffect } from 'react';
import './AttackButton.css';

import { GameState, wordExists, getNewEnemy, doTurn } from '../GameLogic';
import { useLetterStore, useHealthStore, useGameInfoStore } from '../GameStore'
import { gameAnimation } from '../Background/Background';


interface AttackButtonProps {
  gameState: GameState
  updateState: () => void
}

function AttackButton(props: AttackButtonProps) {
  const [writtenLetters] = useLetterStore((state) => ([state.writtenLetters]))
  const [incomingDamage] = useHealthStore((state) => ([state.incomingDamage]))
  let [turn, maxTurn] = useGameInfoStore((state) => ([state.turn, state.maxTurn]))


  const attack = () => {
    for (let word of writtenLetters) {
      if (!wordExists(word) && word.length !== 0) return
    }
    gameAnimation.attack = true
    doTurn(props.gameState)
    props.updateState()
  }

  const attackOnSpace = (e: KeyboardEvent) => {
    if (e.code === 'Space') {
      attack()
    }
  }

  useEffect(() => {
    //typing
    document.addEventListener('keyup', attackOnSpace);
    return () => {
      document.removeEventListener('keyup', attackOnSpace);
    }
  }, [writtenLetters])

  useEffect(() => {
    gameAnimation.floorCondition = Math.max(turn - maxTurn + 4, 0)
  }, [turn])
  return (
    <button className="AttackButton" onClick={attack}>
      <div className='damage-number'>{incomingDamage}</div>
      <img src="/icons/dagger.png" />
    </button >
  );
}

export default AttackButton;
