import React, { useState } from 'react';
import logo from './logo.svg';
import './OwnedItem.css';
import { character, Item } from '../GameLogic';


interface OwnedItemProps {
  item: Item
  amount: number
}


function OwnedItem(props: OwnedItemProps) {

  return (
    <div className={`OwnedItem ${props.item.rarity}`}>
      <div className='icon-container'>
        <img src={'icons/' + props.item.icon} />
        {props.amount > 1 && <p className='amount'>{props.amount}</p>}
      </div>
      <div className='description-box'>
        <p className='item-choice-title'>{props.item.name}</p>
        <p className='item-choice-description'>{props.item.description}</p>
        <p className='font-bold'>{props.item.text?.toUpperCase()}</p>
      </div>
    </div >
  );
}

export default OwnedItem;
