import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './ItemPopup.css';

import { Item, acquireItem } from '../../GameLogic';
import { useOverlayKeyboardStore } from '../../GameStore'
import PopupKeyItem from '../../PopupKeyboard/PopupKeyItem';
import PopupKeyboard from '../../PopupKeyboard/PopupKeyboard';
import ItemChoice from '../ItemChoice/ItemChoice';



function ItemPopup(props: { item: Item, newRound: () => void }) {
  let [text, eraseText] = useOverlayKeyboardStore((state) => ([state.text, state.eraseText]))

  let limitOne = props.item.selectionPrompt === 'Choose a letter:'; //limit to one letter

  useEffect(() => {

  }, [])
  return (
    <div className='ItemPopup'>
      <ItemChoice item={props.item} selected={false} setSelectedItem={() => { }} />
      <div className='flex flex-col items-center'>
        <h1 className='title'>{props.item.selectionPrompt}</h1>
        <div className="flex items-center" style={{ height: 40 }}>
          {text.split('').map((letter) =>
            <PopupKeyItem key={letter} letter={letter} spent={false} write={false} />
          )}

        </div>
      </div>
      <button className='basic-button' onClick={() => {
        if (text === '') { return }
        acquireItem(props.item, text)
        eraseText()
        props.newRound()
      }}>Continue</button>
      <PopupKeyboard writeDisabled={limitOne && text.length > 0} />
    </div>
  );
}

export default ItemPopup;
