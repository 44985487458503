import React, { useState } from 'react';
import logo from './logo.svg';
import './PotionDisplay.css';
import { character, GameState, Item, Potion, consumePotion } from '../../GameLogic';
import { useLetterStore } from '../../GameStore';
import InputPopup from '../../InputPopup/InputPopup';


function PotionDisplay(props: { potion?: Potion, gameState?: GameState, updateState?: () => void }) {
  let [unusedLetters, allLetters, writtenLetters] = useLetterStore((state) => ([state.unusedLetters, state.allLetters, state.writtenLetters]))

  const [popupText, setPopupText] = useState('')

  return (
    <div className={`PotionDisplay${props.potion ? '' : ' empty'}`}>
      {props.potion && <>
        {popupText && <InputPopup prompt={popupText} gameState={props.gameState} onContinue={(text: string) => { consumePotion(props.gameState!, props.potion!, text); props.updateState!(); setPopupText('') }} />}
        <div className='icon-container'>
          <img src={'icons/' + props.potion.icon} />
          {props.potion.uses > 1 && <p className='uses'>{props.potion.uses}</p>}
        </div>
        <div className='description-box'>
          <p className='potion-title'>{props.potion.name} ({props.potion.uses}/{props.potion.maxUses})</p>
          <p className='potion-description'>{props.potion.description}</p>
          {/* <p className='font-bold'>{props.potion.text?.toUpperCase()}</p> */}
          <button className='basic-button' onClick={() => {
            props.gameState!.unusedLetters = [...unusedLetters]
            props.gameState!.allLetters = [...allLetters]
            props.gameState!.writtenLetters = writtenLetters
            if (props.potion!.text) {
              setPopupText(props.potion!.text)
            }
            else {
              consumePotion(props.gameState!, props.potion!)
              props.updateState!();
            }
          }}>Consume</button>
        </div></>}
      {!props.potion &&
        <div className='icon-container'>
          <img style={{ height: '100%', filter: 'contrast(50%)  grayscale(100%)' }} src="/icons/potion_empty.png" />
        </div>}
    </div >
  );
}

export default PotionDisplay;
