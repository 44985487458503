import React, { useState } from 'react';
import logo from './logo.svg';
import './HealthBar.css';

import { useHealthStore } from '../../GameStore'

function HealthBar() {

  let [maxHealth, setMaxHealth] = useHealthStore((state) => ([state.maxHealth, state.setMaxHealth]))
  let [currentHealth, setCurrentHealth] = useHealthStore((state) => ([state.currentHealth, state.setCurrentHealth]))
  let [incomingDamage, setIncomingDamage] = useHealthStore((state) => ([state.incomingDamage, state.setIncomingDamage]))


  let healthPercent: number = Math.max(currentHealth / maxHealth * 100, 0)
  let incomingPercent: number = Math.min(incomingDamage / maxHealth * 100, healthPercent)
  let remainingPercent: number = Math.max(currentHealth / maxHealth * 100 - incomingPercent, 0)
  // console.log(incomingPercent, healthPercent, remainingPercent)


  return (
    <div className="HealthBar" style={{ height: 30, width: 300, borderRadius: 20, position: 'relative' }}>
      <div className="healthbar-text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', position: 'absolute' }}>
        <span>{currentHealth}</span>
        <span>/</span>
        <span>{maxHealth}</span>
      </div>
      <div className="healthbar-background" style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div className={"health-background current border-radius-left-20" + (remainingPercent == 100 ? ' border-radius-right-20' : '')}
          style={{ width: `${remainingPercent}%`, maxWidth: '100%', borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}></div>
        <div className={"health-background incoming" + (remainingPercent <= 0 ? ' border-radius-left-20' : '') + (healthPercent == 100 ? ' border-radius-right-20' : '')}
          style={{ height: '100%', width: `${incomingPercent}%`, maxWidth: `${healthPercent}%` }}>
        </div>
        {/* <div style={{ background: 'grey' }}></div> */}
      </div>
    </div >
  );
}

export default HealthBar;
