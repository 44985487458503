import React from 'react';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import './App.css';
import Game from './components/Game/Game';
import Background from './components/Background/Background';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App" style={{ backgroundColor: '#361030', width: '100%', height: '100%' }}>
        <Game key="game" />
        <Background key="background" />
        {/* <div style={{ backgroundColor: '#361030', zIndex: -5, width: '100%', height: '100%' }} /> */}
      </div>
    </QueryClientProvider >
  );
}

export default App;
